
@import '../../styles/variables';

.mail {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__input {
        width: 292px;
        background-color: #FFFFFF00;
        border: none;
        border-bottom: 1px solid $color-gold;
        margin-bottom: 42px;
        height: 38px;
        border-radius: 0 !important;
        color: $color-white;

        padding: 0 8px;

        &::placeholder {
            color: #B4B4B4;
            font-style: italic;
        }
        &:focus {
            outline: none;
        }
    }
    &__button {
        width: 122px;
        height: 32px;
        border: 1px solid $color-gold;
        margin-bottom: 6.5rem;

        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-gold;
        font-size: 12px;

        cursor: pointer;
        user-select: none;

        text-decoration: none;

        &:hover {
            background-color: #B58B4630;
        }

        &:active {
            background-color: $color-gold;
            color: $color-white;
        }
    }
}
