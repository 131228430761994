@import './_variables.scss';

$bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);

.scrollable {
    position: absolute;
    z-index: 30;
    width: 100vw;
    top: calc(100vh - 88px);
}

.footer-parallax {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    border: 5px solid red;
    background-color: blue;
    z-index: 120;
    color: #FFF;
    font-size: 20px;
    transform: translateZ(-1px) scale(2);
}

.parallax__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 20;
}

.parallax__layer--base {
    position: relative;
    transform: translateZ(0);
    z-index: 22;
    height: calc(100vh - 118px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.parallax__layer--back {
    transform: translateZ(-1px) scale(2);
    height: calc(100vh - 88px);

    background-image: url(./src/home.jpg);
    background-repeat: repeat-y;
    background-position: center;
    background-size: cover;

    background-color: $color-green;
}

.parallax__footer {
    position: absolute;
    width: 100%;
    height: 80px;
    bottom: 0px;
    left: 0;
}

.talk-image {
    width: 100%;
    height: calc((100vh - 8.8rem) * 2 / 3);

    //background-image: url('../assets/images/talk-image.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.talk-section {
    width: 80%;
    max-width: 1365px;
    margin: 42px auto 10rem;
}

.talk-h1 {
    font-family: 'Prata';
    color: $color-gold;
    font-size: 40px;
    line-height: 54px;
    font-weight: 400;
}

.talk-p {
    font-family: 'Volkhov';
    font-size: 20px;
    line-height: 20px;
    margin-top: 24px;
    color: #7F7F7F;
    margin-bottom: 64px;
}

.section-white {
    padding-top: 14.8rem;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #FFF;
    z-index: 120;

    &--last {
        overflow-y: hidden;

        & .section__horizontal {
            width: 414px;
        }

        & .profile-2 {
            margin-left: 4.5rem;
        }

        & .section__p {
            color: #7F7F7F;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 18px;
        }

        & .section__link {
            color: $color-gold;
            font-size: 12px;
            user-select: none;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        & .section__container {
            margin-bottom: 11.6rem;
            align-items: center;
        }

        & .section__p--alone {
            margin: 0 0 16.1rem 0 !important;
        }
    }
}

.section-green {
    background-color: $color-green;
    color: $color-white;

    padding-top: 4.8rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    & .section__h1 {
        width: 293px;
        text-align: center;
    }

    & .section__p {
        max-width: 536px;
        text-align: center;
        color: $color-white;
        margin: 0 3.6rem 6.4rem;
        // margin-bottom: 6.4rem;
    }

    .section__p--footer {
        font-style: italic;
        margin-bottom: 2.4rem;
    }
}

.section {
    &__container {
        display: flex;
    }

    &__h1 {
        font-family: 'Prata';
        font-size: 40px;
        line-height: 54px;
        color: $color-gold;
        font-weight: 400;

        &--alone {
            margin-top: 6.8rem;
        }
    }

    &__p {
        margin-top: 4rem;
        color: #7F7F7F;
        font-size: 20px;
        line-height: 30px;

        &--italic {
            font-style: italic;
        }

        &--alone {
            margin: 16rem 36px;
        }

        &--centered {
            margin: 6.8rem 3.6rem 0 !important;
            text-align: center;
            margin-bottom: 16rem !important;
        }
    }

    &__list {
        margin-top: 4.8rem;
        list-style: none;
        color: #7F7F7F;

        & li {
            font-size: 20px;
            margin-bottom: 3.6rem;

            &::before {
                content: "•";
                color: $color-gold;
                margin-right: 5px;
            }
        }
    }

    &__card {
        width: 300px;

        &-h2 {
            font-size: 30px;
            font-weight: bold;
            color: $color-gold;
            margin-bottom: 4.8rem;
        }

        &-p {
            font-size: 1.4rem;
            line-height: 24px;
            font-weight: 400;
            color: #7F7F7F;
        }
    }

    &__icon-full {
        display: inline-block;
        width: 100px;
        height: 100px;
        background-color: $color-green;

        background-image: url(../assets/icons/logo-gold.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24%;

        clip-path: polygon(0 50%, 50% 100%, 100% 50%, 50% 0);
    }
}

.section__horizontal {
    &--centered {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--small {
        width: 38rem;
    }
}

.profile-1 {
    margin-right: 56px;
}

#scroll-1 {
    transform: translateX(-40px);
    opacity: 0;

    transition: transform .3s $bounce .2s, opacity .3s $bounce .2s;

    &.shown {
        transform: translateX(0px);
        opacity: 1;
    }
}

#scroll-2 {
    transform: translateX(40px);
    opacity: 0;

    transition: transform .3s $bounce .3s, opacity .3s $bounce .3s;

    &.shown {
        transform: translateX(0px);
        opacity: 1;
    }
}

#scroll-2 {
    transform: translateX(40px);
    opacity: 0;

    transition: transform .3s $bounce .2s, opacity .3s $bounce .2s;

    &.shown {
        transform: translateX(0px);
        opacity: 1;
    }
}

#scroll-3 {
    transform: translateX(40px);
    opacity: 0;

    transition: transform .3s $bounce .3s, opacity .3s $bounce .3s;

    &.shown {
        transform: translateX(0px);
        opacity: 1;
    }
}

#scroll-4 {

    & li {
        transform: translateX(40px);
        opacity: 0;

        &:nth-child(1) {
            transition: transform .3s $bounce .4s, opacity .3s $bounce .4s;
        }

        &:nth-child(2) {
            transition: transform .3s $bounce .5s, opacity .3s $bounce .5s;
        }

        &:nth-child(3) {
            transition: transform .3s $bounce .6s, opacity .3s $bounce .6s;
        }

        &:nth-child(4) {
            transition: transform .3s $bounce .7s, opacity .3s $bounce .7s;
        }

        &:nth-child(5) {
            transition: transform .3s $bounce .8s, opacity .3s $bounce .8s;
        }
    }

    &.shown {
        & li {
            transform: translateX(0px);
            opacity: 1;
        }
    }
}

#scroll-5 {
    transform: translateY(-60px) scale(1.1);
    opacity: 0;

    transition: transform .3s $bounce .3s, opacity .3s $bounce .3s;

    &.shown {
        transform: translateX(0px) scale(1);
        opacity: 1;
    }
}

#scroll-6 {
    & .section__card {
        transform: translateY(-50px) scale(1.1);
        opacity: 0;

        &:nth-child(1) {
            transition: transform .3s $bounce .3s, opacity .3s $bounce .3s;
        }

        &:nth-child(2) {
            transition: transform .3s $bounce .4s, opacity .3s $bounce .4s;
        }

        &:nth-child(3) {
            transition: transform .3s $bounce .5s, opacity .3s $bounce .5s;
        }
    }

    &.shown {
        & .section__card {
            transform: translateY(0px) scale(1);
            opacity: 1;
        }
    }
}

#scroll-7 {
    transform: scale(0);
    opacity: 0;

    transition: transform .3s $bounce .4s, opacity .3s $bounce .4s;

    &.shown {
        transform: scale(1);
        opacity: 1;
    }
}

#scroll-8 {
    transform: translateY(-50px);
    opacity: 0;

    transition: transform .3s $bounce .4s, opacity .3s $bounce .4s;

    &.shown {
        transform: translateY(0);
        opacity: 1;
    }
}

#scroll-9 {
    transform: translateY(-50px);
    opacity: 0;

    transition: transform .3s $bounce .4s, opacity .3s $bounce .4s;

    &.shown {
        transform: translateY(0);
        opacity: 1;
    }
}

#scroll-10 {
    transform: translateY(-50px);
    opacity: 0;

    transition: transform .3s $bounce .4s, opacity .3s $bounce .4s;

    &.shown {
        transform: translateY(0);
        opacity: 1;
    }
}

#scroll-11 {
    transform: translateY(-40px) scale(1.1);
    opacity: 0;

    transition: transform .3s $bounce .4s, opacity .3s $bounce .4s;

    &.shown {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

#scroll-12 {
    transform: scale(1.2);
    opacity: 0;

    transition: transform .3s $bounce .4s, opacity .3s $bounce .4s;

    &.shown {
        transform: scale(1);
        opacity: 1;
    }
}

#scroll-13 {
    max-width: 450px !important;
    transform: translateY(-20px) scale(1.6);
    opacity: 0;

    transition: transform .3s $bounce .4s, opacity .3s $bounce .4s, max-width .3s $bounce .4s;

    &.shown {
        max-width: 536px !important;
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

#scroll-14 {
    transform: translateX(-40px);
    opacity: 0;

    transition: transform .3s $bounce .3s, opacity .3s $bounce .3s;

    &.shown {
        transform: translateX(0px);
        opacity: 1;
    }
}

#scroll-15 {
    transform: translateX(-40px);
    opacity: 0;

    transition: transform .3s $bounce .4s, opacity .3s $bounce .4s;

    &.shown {
        transform: translateX(0px);
        opacity: 1;
    }
}

#scroll-17 {
    transform: translateX(-40px);
    opacity: 0;

    transition: transform .3s $bounce .4s, opacity .3s $bounce .4s;

    &.shown {
        transform: translateX(0px);
        opacity: 1;
    }
}

#scroll-16 {
    transform: translateX(40px);
    opacity: 0;

    transition: transform .3s $bounce .3s, opacity .3s $bounce .3s;

    &.shown {
        transform: translateX(0px);
        opacity: 1;
    }
}

#scroll-18 {
    transform: translateY(40px);
    opacity: 0;

    transition: transform .3s $bounce .3s, opacity .3s $bounce .3s;

    &.shown {
        transform: translateY(0px);
        opacity: 1;
    }
}

#scroll-19 {}

@media only screen and (max-width: 930px) {
    .section__container {
        flex-direction: column;
        align-items: center;

        & .section__card {
            margin-bottom: 4.6rem;

            width: 400px !important;
        }
    }

    .section__h1--top {
        margin-top: 4.6rem;
    }

    .section__p {
        margin-top: 2.4rem;
    }

    .section__p--alone {
        margin: 4.4rem 3.6rem 7.1rem;
    }

    .section__list {
        margin-top: 2.4rem;
    }

    .profile-1 {
        margin-right: 0;
        transform: translateY(-60px);
        opacity: 0;
        transition: transform .3s ease, opacity .3s ease;

        &.shown {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .profile-2 {
        margin-left: 0 !important;
        margin-top: 6.4rem;
    }
}

@media only screen and (max-width: 1050px) {
    .section__container--cards {
        & .section__card {
            width: 250px
        }
    }
}

@supports (-webkit-touch-callout: none) {
    .header-1 {
        margin-top: -80px;
    }

    & .content__footer {
        padding-bottom: 100px;
        height: 50rem !important;
    }

    .scrollable {
        // padding-bottom: 100px;
        // &::after {
        //     background-color: red;
        //     content: "";
        //     display: block;
        //     width: 100%;
        //     height: 100px;
        //     position: absolute;
        //     bottom: 0px;
        //     left: 0;
        // }
    }
}

@media only screen and (max-width: 400px) {
    .jumbotron__text {
        width: 75% !important;
    }
}

@media only screen and (max-width: 500px) {
    .profile-1 {
        width: 100%;
    }

    .section-white {
        padding-top: 8rem;
    }

    .parallax__layer--base {
        height: calc(100vh - 44px);

        &>.jumbotron__text {
            margin-top: -25px;
        }
    }

    .scrollable-1 {
        top: calc(100vh);
    }

    .parallax__layer--back {
        // height: 100vh;
    }


    .section__container {
        width: calc(100vw - 74px);

        & .section__card {
            width: 90% !important;

            &-p {
                text-align: justify;
            }

        }

        &--cards {
            margin-bottom: 8rem !important;
        }
    }

    .section__horizontal--small {
        width: 100%;
    }

    .section__h1--alone {
        margin-top: 4.6rem;
    }

    .section__p--centered {
        text-align: justify;
        margin-top: 4.8rem !important;
        margin-bottom: 8rem !important;
    }

    .profile-2 {
        margin-left: 0 !important;
        margin-top: 6.4rem;
    }

    .section-white--last {
        & .section__h1 {
            margin: 0;
        }

        & .section__p {
            margin: 42px 0 12px;
        }

        & .section__link {
            margin: 2.4rem 0;
        }

        & .section__p--alone {
            margin: 0 36px 8rem !important;
            font-size: 20px;
            text-align: center;
        }

        & .section__container {
            width: calc(100vw - 72px);

            & .section__horizontal--small {
                width: 100%;
                // border: 3px solid red;
            }

            & .profile-2 {
                width: 100%;
            }
        }
    }

    // .section__icon-full {

    // }
}
