@import '_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato');
@import url('https://fonts.googleapis.com/css2?family=Prata');
@import url('https://fonts.googleapis.com/css2?family=Volkhov:ital@1&display=swap');


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html {
    font-size: 10px;
    font-family: 'Lato', sans-serif;
}

body {
    font-size: 1.6rem;
    width: 100vw;
    overflow: hidden !important;
}

#root {
    position: relative;
    display: block;
    height: 100vh;
    overflow: hidden !important;
}

::-webkit-scrollbar {
    display: none;
}